import React from 'react';
import { Link } from 'gatsby';
import Layout from 'components/Layout';
import styled from 'styled-components';
import Header from 'components/Header';
import headerPro from 'images/headerPro.svg';
import { Section, Container, Buttons, Button } from 'ui';
import oups from 'images/oups.png';
import { MdChevronLeft } from 'react-icons/md';

const Content = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 260px;
  }

  p {
    font-weight: 500;
    font-size: 1.5rem;
  }
`;

const NotFoundPage = ({ location }) => {
  const isPro = location.pathname.indexOf('/pro/') === 0;

  return (
    <Layout>
      <Header title="" icon={headerPro} variant="withLogo" />
      <Section>
        <Container>
          <Content>
            <img src={oups} alt="oups"></img>
            <p>Cette page est introuvable</p>
            <Buttons>
              <Button variant="primary" as={Link} to={isPro ? '/pro' : '/'}>
                <MdChevronLeft />
                Retour à l'accueil
              </Button>
            </Buttons>
          </Content>
        </Container>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
